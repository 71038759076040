$color-active: #e4000a;
$color-hover: #750000;
$color-normal: #e4000a;
$color-rojo: #750000;
$color-azul: #0b32a6;

// Paleta de colores
$color-navy: #0C2D57;
$color-navy-hover: #1c5aac;
$color-naranja: #FC6736;
$color-naranja-hover: #fa5017;
$color-amarillo: #F8DE22;
$color-amarillo-hover: #feea52;
$color-rosa: #FFB0B0;
$color-rosa-hover: #f49494;
$color-gris: #EFECEC;
$color-gris-hover: #d2c7c7;
$color-texto: #54555B;
$color-texto-bold: #3f4048;
